import {
    Box,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepTitle,
    StepDescription,
    StepIcon,
    StepNumber,
    StepSeparator,
    VStack,
    useSteps,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    HStack,
    Button,
    Spacer,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { API_URLS } from "../Constants";
import { useCookies } from 'react-cookie';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { AppContext } from "../providers/AppProvider";
import { QuestionContext } from "../providers/QuestionProvider";
import AppointmentDatetime from "../components/appointment/appointment-datetime";
import AppointmentCategory from "../components/appointment/appointment-category";
import AppointmentQuestions from "../components/appointment/appointment-questions";

function CreateAppointment() {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        onNext,
        onPrev,
        date,
        writingTime,
        sharingTime,
        currentCategory,
        currentQuestion,
        setCurAppointment,
        currentQuestionID,
    } = useContext(QuestionContext);
    const { setSubscription } = useContext(AppContext);
    const toast = useToast();
    const navigate = useNavigate();
    const { cookieAlive } = useContext(AuthContext);
    const [cookies] = useCookies(['dd_user_email']);
    const [shouldDisable, setShouldDisable] = useState(false);
    const steps = [
        {
            title: "Appointments",
            description: "Set Appointments",
        },
        {
            title: "Category",
            description: "Choose Dialogue Category",
        },
        {
            title: "Question",
            description: "Choose Dialogue Question",
        },
    ];

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

    }, [cookieAlive, navigate])

    useEffect(() => {
        const getCurUserCategory = async () => {
            try {
                const response = await axios.get(API_URLS.DIALOGUE_CHECK_COUNT, {
                    params: {
                        email: cookieAlive()
                    }
                });

                if (response.data.success) {

                    const appointments = response.data.data;
                    setCurAppointment(appointments);
                    setSubscription(response.data.subscription);
                    setShouldDisable(response.data.shouldDisable);
                    
                    if (response.data.shouldDisable) {
                        toast({
                            title: "User Subscription",
                            description: response.data.message,
                            duration: 3000,
                            isClosable: true,
                            bg: 'blue',
                            color: 'white'
                        });
                    }
                } else {
                    toast({
                        title: "User Category",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                        color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (err) {
                console.log(err)
            }
        }
        if (cookieAlive()) {
            getCurUserCategory();
        }
    }, [cookieAlive, toast])

    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    });

    const handlePrev = () => {
        let step = activeStep;
        const prevResult = onPrev(step);
        if (!prevResult.result) {
            toast({
                title: "Create Dialogue",
                description: `${prevResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: 'blue',
                color: 'white'
            });
            return;
        }
        if (activeStep - 1 < 1) step = 1;
        else step = activeStep - 1;
        setActiveStep(step);
    };

    const handleNext = async () => {
        let step = activeStep;
        const nextResult = await onNext(step);
        if (!nextResult.result) {
            toast({
                title: "Create Dialogue",
                description: `${nextResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: 'blue',
                color: 'white'
            });
            return;
        }
        if (activeStep + 1 > 3) onOpen();
        else step = activeStep + 1;

        setActiveStep(step);
    };

    const handleSaveDialogue = async () => {
        try {
            const response = await axios.post(API_URLS.DIALOGUE_CREATE, {
                date,
                writingTime,
                sharingTime,
                currentCategoryId: currentCategory,
                category: currentCategory,
                question: currentQuestion,
                questionID: currentQuestionID,
                myEmail: cookies['dd_user_email'],
                spouseEmail: cookies['dd_spouse_email']
            });

            if (response.data.success) {
                sendEmail(date, writingTime, sharingTime, currentQuestion);

                onClose();
                navigate('/');
            } else {
                toast({
                    title: "Create Dialogue",
                    description: `${response.data.message}.`,
                    bg: 'blue',
                    color: 'white',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: "Create Dialogue",
                description: `${e.message}.`,
                bg: 'blue',
                color: 'white',
                duration: 3000,
                isClosable: true,
            });
        } finally {
        }
    };

    const sendEmail = async (date, writingTime, sharingTime, currentQuestion) => {
        try {
            const response = await axios.post(API_URLS.SEND_EMAIL, {
                type: "create",
                ownerEmail: cookies["dd_user_email"],
                email: cookies["dd_spouse_email"],
                date: date,
                writingTime: writingTime,
                sharingTime: sharingTime,
                content: `${cookies["dd_words_appointment"] ? cookies["dd_words_appointment"] : ""} <br> <b>${currentQuestion}</b>`
            });

            if (response.data?.success === true) {
                toast({
                    title: "Appointment Email",
                    description: response.data.message,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Appointment Email",
                    description: `${response.data.message}.`,
                    bg: 'blue',
                    color: 'white',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: "Appointment Email",
                description: `${e.message}.`,
                bg: 'blue',
                color: 'white',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const handleBack = () => {
        navigate("/");
    }

    return (
        <Box w={"auto"}>
            <VStack w={"auto"} spacing={8} p={8}>
                <Card w={isLargerThan830 ? "auto" : "-webkit-fill-available"} minH={"400px"} minW={isLargerThan830 ? "400px" : "350px"}>
                    <VStack align="end" mr={8}>
                        <Button w={100} mt={4} onClick={handleBack} size={'sm'} _hover={{ bg: '#3182ce', color: 'white' }}>
                            Back
                        </Button>
                    </VStack>
                    <CardHeader>
                        <Stepper
                            index={activeStep}
                            flexDirection={isLargerThan830 ? "row" : "column !important"}
                            alignItems={isLargerThan830 ? "center" : "start !important"}
                            paddingLeft={isLargerThan830 ? "0" : "15px !important"}
                        >
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink="0">
                                        <StepTitle>{step.title}</StepTitle>
                                        <StepDescription>
                                            {step.description}
                                        </StepDescription>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>
                    </CardHeader>
                    <CardBody>
                        {activeStep === 1 && <AppointmentDatetime />}
                        {activeStep === 2 && <AppointmentCategory />}
                        {activeStep === 3 && <AppointmentQuestions />}
                    </CardBody>
                    <CardFooter>
                        <HStack w={"full"}>
                            <Button
                                onClick={handlePrev}
                                _hover={{ bg: '#3182ce', color: 'white' }}
                                isDisabled={activeStep === 1 || shouldDisable}
                            >
                                Prev
                            </Button>
                            <Spacer />
                            <Button
                                onClick={handleNext}
                                _hover={{ bg: '#3182ce', color: 'white' }}
                                isDisabled={shouldDisable}
                            >
                                {activeStep === 1 ?
                                    'Next' : activeStep === 2 ? 'Next' : 'Finish'
                                }
                            </Button>
                        </HStack>
                    </CardFooter>
                </Card>
            </VStack>
            <AlertDialog isOpen={isOpen} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent mx={8}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Create Appointment
                        </AlertDialogHeader>

                        <AlertDialogBody>Are you sure you want to save this appointment?</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button
                                colorScheme="teal"
                                onClick={handleSaveDialogue}
                                ml={3}
                            >
                                Save
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
}

export default CreateAppointment;
