import {
    Box,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepTitle,
    StepDescription,
    StepIcon,
    StepNumber,
    StepSeparator,
    VStack,
    useSteps,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    HStack,
    Button,
    Spacer,
    useToast,
    useDisclosure,
    Heading,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { API_URLS } from "../Constants";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { DialogueContext } from "../providers/DialogueProvider";
import DialogueEmotion from "../components/dialogue/dialogue-emotion";
import DialogueCategory from "../components/dialogue/dialogue-category";
import VideoDialogBox from "./VideoDialogBox";

function VideoDialogue() {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        onNext,
        onPrev,
        emotion,
        addFlag,
        selDialogId,
        setEmotion,
        setFeelingData,
        setContent,
        shareFlag,
        setAddFlag
    } = useContext(DialogueContext);
    const { cookieAlive } = useContext(AuthContext);
    const location = useLocation();
    const [cookies] = useCookies();
    const toast = useToast();
    const navigate = useNavigate();
    const steps = [
        {
            title: "Emotion",
            description: "Choose Emotion Category",
        },
        {
            title: "Feelings",
            description: "Choose Feelings (Max 3)",
        },
        {
            title: "Video",
            description: "Record Dialogue Video",
        },
    ];

    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    });

    useEffect(() => {
        // Check the video is exist
        const getCheckExistVideo = async () => {
            setEmotion("");
            setFeelingData([]);
            setContent("");

            const response = await axios.post(shareFlag ? API_URLS.DIALOGUE_GET_SHARE_APPT_VIDEO : API_URLS.DIALOGUE_GET_SEL_APPT_VIDEO, {
                appointId: selDialogId,
                userEmail: cookies["dd_user_email"]
            });

            if (response.data.videoPath) {
                setAddFlag(false);
                setEmotion(response.data.emotion);
                setFeelingData(JSON.parse(response.data.feelings));
                setContent(`${process.env.REACT_APP_UPLOAD_URL}${response.data.videoPath}`);
            } else {
                setAddFlag(true);
            }
        };
        getCheckExistVideo();
    }, [shareFlag, selDialogId])

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        if (!selDialogId) {
            navigate("/");
        }
    }, [cookieAlive, selDialogId, navigate]);

    const handlePrev = () => {
        let step = activeStep;
        const prevResult = onPrev(step);
        if (!prevResult.result) {
            toast({
                title: "Record Dialogue",
                description: `${prevResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: 'blue',
                color: 'white'
            });
            return;
        }
        if (activeStep - 1 < 1) step = 1;
        else step = activeStep - 1;
        setActiveStep(step);
    };

    const handleNext = () => {
        let step = activeStep;
        const nextResult = onNext(step);
        if (!nextResult.result) {
            toast({
                title: "Record Dialogue",
                description: `${nextResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: 'blue',
                color: 'white'
            });
            return;
        }
        if (activeStep + 1 > 3) {
            navigate('/');
        }
        else
            step = activeStep + 1;

        setActiveStep(step);
    };

    const handleBack = () => {
        navigate("/");
    }

    return (
        <Box w={"full"}>
            {
                addFlag ? (
                    <>
                        <VStack w={"full"} spacing={8}>
                            <Card w={isLargerThan830 ? "auto" : "-webkit-fill-available"} minH={"400px"} minW={isLargerThan830 ? "400px" : "350px"} mx={isLargerThan830 ? 0 : 8}>
                                <VStack align="end" mr={8}>
                                    <Button w={100} mt={4} onClick={handleBack} size={'sm'} _hover={{ bg: '#3182ce', color: 'white' }}>
                                        Back
                                    </Button>
                                </VStack>
                                <CardHeader>
                                    <Stepper
                                        index={activeStep}
                                        flexDirection={isLargerThan830 ? "row" : "column !important"}
                                        alignItems={isLargerThan830 ? "center" : "start !important"}
                                        paddingLeft={isLargerThan830 ? "0" : "15px !important"}
                                    >
                                        {steps.map((step, index) => (
                                            <Step key={index}>
                                                <StepIndicator>
                                                    <StepStatus
                                                        complete={<StepIcon />}
                                                        incomplete={<StepNumber />}
                                                        active={<StepNumber />}
                                                    />
                                                </StepIndicator>

                                                <Box flexShrink="0">
                                                    <StepTitle>{step.title}</StepTitle>
                                                    <StepDescription>
                                                        {step.description}
                                                    </StepDescription>
                                                </Box>

                                                <StepSeparator />
                                            </Step>
                                        ))}
                                    </Stepper>
                                </CardHeader>
                                <CardBody>
                                    {activeStep === 1 && <DialogueEmotion handleEmotionSelect={handleNext} />}
                                    {activeStep === 2 && <DialogueCategory />}
                                    {activeStep === 3 && <VideoDialogBox type={'master'} />}
                                </CardBody>
                                <CardFooter>
                                    {activeStep !== 1 && (
                                        <HStack w={"full"}>
                                            <Button
                                                onClick={handlePrev}
                                                disabled={activeStep === 1}
                                                _hover={{
                                                    bg: "#3182ce", color: 'white'
                                                }}
                                            >
                                                Prev
                                            </Button>
                                            <Spacer />
                                            <Button
                                                onClick={handleNext}
                                                disabled={activeStep === 3}
                                                _hover={{
                                                    bg: "#3182ce", color: 'white'
                                                }}
                                            >
                                                {activeStep === 1 ?
                                                    'Next' : activeStep === 2 ? 'Next' : 'Finish'
                                                }
                                            </Button>
                                        </HStack>
                                    )}
                                </CardFooter>
                            </Card>
                        </VStack>
                    </>
                ) : (
                    <VStack w={"full"} spacing={8}>
                        <Card w={isLargerThan830 ? "auto" : "-webkit-fill-available"} minH={"400px"} minW={isLargerThan830 ? "400px" : "350px"} mx={isLargerThan830 ? 0 : 8}>
                            <CardBody>
                                <Heading fontSize='xl' mt={4}>Question</Heading>
                                <Text mt={2}>{location.state.question}</Text>
                                <Heading fontSize='xl' mt={4}>Emotion</Heading>
                                <Text mt={2}>{emotion === 'Yes' ? 'Pleasant' : 'Unpleasant'}</Text>
                                <Heading fontSize='xl' mt={4}>Video</Heading>
                                <VideoDialogBox type={'master'} />

                            </CardBody>
                            <CardFooter>
                                <HStack justifyContent={"flex-end"} w={'full'}>
                                    <Button onClick={handleBack} colorScheme="blue">
                                        Back
                                    </Button>
                                </HStack>
                            </CardFooter>
                        </Card>
                    </VStack>
                )
            }
        </Box>
    );
}

export default VideoDialogue;
