import axios from "axios";
import { useCookies } from 'react-cookie';
import { useToast } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { FaCalendar, FaEdit, FaShare } from "react-icons/fa";
import { HStack, Text, Input, Container } from "@chakra-ui/react";

import { API_URLS } from "../../Constants";
import { timeFormat } from "../../utils/utils";
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { QuestionContext } from "../../providers/QuestionProvider";
import moment from 'moment';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
function AppointmentDatetime() {
    const toast = useToast();
    const {
        date,
        setDate,
        writingTime,
        setWritingTime,
        sharingTime,
        setSharingTime,
    } = useContext(QuestionContext);
    const [cookies] = useCookies();
    const { cookieAlive } = useContext(AuthContext);
    const { appointment, setAppointment } = useContext(AppContext);
    const today = moment().format('YYYY-MM-DD');
    const dayKeys = [
        { writing: "Sun_writing", sharing: "Sun_sharing" },
        { writing: "Mon_writing", sharing: "Mon_sharing" },
        { writing: "Tue_writing", sharing: "Tue_sharing" },
        { writing: "Wed_writing", sharing: "Wed_sharing" },
        { writing: "Thu_writing", sharing: "Thu_sharing" },
        { writing: "Fri_writing", sharing: "Fri_sharing" },
        { writing: "Sat_writing", sharing: "Sat_sharing" },
    ];

    useEffect(() => {

        const setDefaultDate = async (appointmentData) => {
            try {
                const response = await axios.post(API_URLS.DIALOGUE_ALL_APPTS, { email: cookies['dd_user_email'] });

                if (response.data.success) {
                    const appointments = response.data.data;
                    const appointmentDates = appointments.map(app => app.date);
                    let defaultDate = moment();
                    let formattedDate = defaultDate.format('YYYY-MM-DD');

                    while (appointmentDates.includes(formattedDate)) {
                        defaultDate.add(1, 'days');
                        formattedDate = defaultDate.format('YYYY-MM-DD');
                    }
                    setDate(formattedDate);

                    // Set the default writing and sharing date based on the default date.
                    const day = defaultDate.day();
                    const { writing, sharing } = dayKeys[day];
                    const newWritingTime = timeFormat(appointmentData[writing]);
                    const newSharingTime = timeFormat(appointmentData[sharing]);
                    // if (writingTime.length === 0) {
                        setWritingTime(newWritingTime);
                    // }
                    // if (sharingTime.length === 0) {
                        setSharingTime(newSharingTime);
                    // }
                }
            } catch (e) {
                toast({
                    title: "Create Dialogue Date",
                    description: `${e.message}.`,
                    bg: 'blue',
                    color: 'white',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        const loadAppointment = async () => {
            if (!cookieAlive()) return;

            try {
                const response = await axios.post(API_URLS.APPT_INFO, {
                    email: cookieAlive()
                });

                if (response.data.success) {
                    const appointmentData = response.data.data;
                    setAppointment(appointmentData);
                    setDefaultDate(appointmentData);
                } else {
                    toast({
                        title: "Appointment Defaults",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                        color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (err) {
            }
        };
        // setDefaultDate();
        loadAppointment();
    }, [cookieAlive, setAppointment, toast]);

    const handleChangeDate = (e) => {
        setDate(e.target.value);

        const day = moment(e.target.value).day();
        const { writing, sharing } = dayKeys[day];
        const newWritingTime = timeFormat(appointment[writing]);
        const newSharingTime = timeFormat(appointment[sharing]);
        setWritingTime(newWritingTime);
        setSharingTime(newSharingTime);
    };

    const handleChangeWritingTime = (e) => {
        const v = dayjs(e).format('HH:mm');
        setWritingTime(v);
    };

    const handleChangeSharingTime = (e) => {
        const v = dayjs(e).format('HH:mm');
        setSharingTime(v);
    };

    return (
        <Container>
            <HStack spacing={4} mb={2}>
                <HStack width={125}>
                    <FaCalendar />
                    <Text>Date</Text>
                </HStack>
                <Input
                    type="date"
                    onChange={handleChangeDate}
                    min={today}
                    value={date}
                    w={'200px'}
                    p={'4px 11px'}
                />
            </HStack>
            <HStack spacing={4} mb={2} height={'40px'}>
                <HStack width={125}>
                    <FaEdit />
                    <Text>Writing</Text>
                </HStack>
                <TimePicker use12Hours format="h:mm a" value={dayjs(writingTime, 'HH:mm')} onChange={handleChangeWritingTime} style={{ width: '200px', height: '40px', fontSize: '1rem' }} />
            </HStack>
            <HStack spacing={4} mb={2} height={'40px'}>
                <HStack width={125}>
                    <FaShare />
                    <Text >Sharing</Text>
                </HStack>
                <TimePicker use12Hours format="h:mm a" value={dayjs(sharingTime, 'HH:mm')} onChange={handleChangeSharingTime} style={{ width: '200px', height: '40px', fontSize: '1rem' }} />
            </HStack>
        </Container>
    );
}

export default AppointmentDatetime;
