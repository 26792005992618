import axios from "axios";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { FaEdit, FaQuestion, FaShare } from "react-icons/fa";
import { useContext, useEffect, useCallback, useState } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Text,
    Input,
    Spacer,
    VStack,
    HStack,
    useToast,
    ButtonGroup,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Box
} from "@chakra-ui/react";

import { API_URLS } from "../Constants";
import { timeFormat } from "../utils/utils";
import { AppContext } from "../providers/AppProvider";
import { AuthContext } from "../providers/AuthProvider";
import { DialogueContext } from "../providers/DialogueProvider";
import moment from "moment";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
const NextAppointmentItem = ({ data, onItemEvent }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const { cookieAlive } = useContext(AuthContext);
    const { setSelDialogId, dialogueDate, setDialogueDate, setShareFlag, setWritingTime, setSharingTime } = useContext(DialogueContext);
    const { appointment, setAppointment } = useContext(AppContext);
    const [curWritingTime, setCurWritingTime] = useState("");
    const [curSharingTime, setCurSharingTime] = useState("");
    const [appointmentState, setAppointmentState] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const checkAppointmentState = async () => {
            try {
                const response = await axios.post(API_URLS.DIALOGUE_CHECK_APPT_STATE, {
                    appointmentId: data?._id,
                    userEmail: cookies["dd_user_email"]
                });

                if (response.data.success) {
                    setAppointmentState(response.data.data);
                } else {
                    toast({
                        title: "Check Appointment State", description: response.data.message, bg: 'blue',
                        color: 'white', duration: 3000, isClosable: true
                    });
                }
            } catch (err) {
                toast({
                    title: "Check Appointment State", description: "Failed to check share state.", bg: 'blue',
                    color: 'white', duration: 3000, isClosable: true
                });
            }
        };

        if (data) {
            setCurWritingTime(data.writingTime);
            setCurSharingTime(data.sharingTime);
            setWritingTime(data.writingTime);
            setSharingTime(data.sharingTime);
            checkAppointmentState();
        } else if (Object.keys(appointment).length > 0) {
            const dayIndex = moment(data.date).day();
            const writingKey = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayIndex] + '_writing';
            const sharingKey = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayIndex] + '_sharing';
            setCurWritingTime(timeFormat(appointment[writingKey]));
            setCurSharingTime(timeFormat(appointment[sharingKey]));
            setWritingTime(timeFormat(appointment[writingKey]));
            setSharingTime(timeFormat(appointment[sharingKey]));
        }

        const loadAppointment = async () => {
            if (!cookieAlive()) return;
            try {
                const response = await axios.post(API_URLS.APPT_INFO, { email: cookieAlive() });
                if (response.data.success) {
                    setAppointment(response.data.data);
                } else {
                    toast({
                        title: "Upcoming Appointments", description: response.data.message, bg: 'blue',
                        color: 'white', duration: 3000, isClosable: true
                    });
                }
            } catch (err) {
                toast({
                    title: "Error", description: "Failed to load appointments.", bg: 'blue',
                    color: 'white', duration: 3000, isClosable: true
                });
            }
        };
        loadAppointment();

        setSelDialogId(data?._id);
        setDialogueDate(data?.date);
    }, [cookieAlive, data, setAppointment, setDialogueDate, setSelDialogId, toast]);

    const checkTime = (time) => {
        const appointmentDateTime = moment(`${data.date}T${time}`).toDate();
        const currentTimeUTC = moment.utc().toDate();
        if (appointmentState.type !== '') return true;

        if (moment(data.date).isAfter(moment().startOf('day'))) {
            toast({
                title: "Appointment", description: "Come back and Dialogue on the right date.", duration: 3000, isClosable: true, bg: 'blue',
                color: 'white'
            });
            return false;
        } else if (moment(data.date).isSame(moment().startOf('day'))) {
            // if (currentTimeUTC < appointmentDateTime) {
            //     toast({
            //         title: "Appointment", description: `It's not the time yet.`, duration: 3000, isClosable: true, bg: 'blue',
            //         color: 'white'
            //     });
            //     return false;
            // }
        }
        return true;
    };

    const handleGoto = (type) => {
        if (data?._id) {
            setSelDialogId(data._id);
            setShareFlag(false);
        }

        if (checkTime(curWritingTime, type)) {
            if (type === 'write') {
                navigate('/write', { state: { question: data.question } });
            } else if (type === 'video') {
                navigate('/video', { state: { question: data.question } });
            } else if (type === 'audio') {
                navigate('/audio', { state: { question: data.question } });
            }
        }
    };

    const handleReadyShare = useCallback(async (share_type) => {
        if (data?._id && checkTime(curSharingTime, 'share')) {
            try {
                const response = await axios.post(API_URLS.DIALOGUE_PRESHARE_APPT, {
                    appointmentId: data._id,
                    userEmail: cookies["dd_user_email"]
                });

                if (response.data.success)
                {
                    // toast({ title: "Share Appointment", description: response.data.message, status: "success", duration: 3000, isClosable: true });
                    sendEmail();
                    onItemEvent('share', data._id, share_type);
                } 
                else {
                    toast({
                        title: "Share Appointment", description: response.data.message, bg: 'blue',
                        color: 'white', duration: 3000, isClosable: true
                    });
                }
            } catch (err) {
                toast({
                    title: "Error", description: "Failed to share appointment.", bg: 'blue',
                    color: 'white', duration: 3000, isClosable: true
                });
            }
        }
    });

    const handleRemove = async () => {
        try {
            const response = await axios.post(API_URLS.DIALOGUE_REMOVE_APPT, { appointmentId: data._id });
            if (response.data.success) {
                toast({ title: "Appointment", description: response.data.message, status: "success", duration: 3000, isClosable: true });
                onItemEvent('remove', data._id);
            } else {
                toast({
                    title: "Remove Appointment", description: response.data.message, bg: 'blue',
                    color: 'white', duration: 3000, isClosable: true
                });
            }
        } catch (err) {
            toast({
                title: "Error", description: "Failed to remove appointment.", bg: 'blue',
                color: 'white', duration: 3000, isClosable: true
            });
        }

        onClose();
    };

    const sendEmail = async () => {
        try {
            const response = await axios.post(API_URLS.SEND_EMAIL, {
                type: "share",
                ownerEmail: cookies["dd_user_email"], // Populate as needed
                email: cookies["dd_spouse_email"],
                date: dialogueDate, // Populate as needed
                writingTime: "", // Populate as needed
                sharingTime: "", // Populate as needed
                content: `${cookies["dd_words_share"] ? cookies["dd_words_share"] : ""} <br> <b>I am ready to share our dialogue.</b>`
            });

            if (response.data?.success) {
                toast({ title: "Share Email", description: response.data.message, status: "success", duration: 3000, isClosable: true });
            } else {
                toast({
                    title: "Share Email", description: response.data.message, bg: 'blue',
                    color: 'white', duration: 3000, isClosable: true
                });
            }
        } catch (e) {
            toast({
                title: "Share Email", description: e.message, bg: 'blue',
                color: 'white', duration: 3000, isClosable: true
            });
        }
    };

    return (
        <Card w={'auto'}>
            <CardBody>
                <VStack align={'start'} gap={4}>
                    <HStack gap={4} w={'full'}>
                        <Box minW={'20px'}>
                            <FaEdit />
                        </Box>
                        <Text fontSize={16} minW={'92px'}>Writing Time</Text>
                        <Spacer />
                        <TimePicker
                            use12Hours
                            format="h:mm a"
                            value={dayjs(curWritingTime, 'HH:mm')}
                            w={'10rem'}
                            onChange={e => {
                                const v = dayjs(e).format('HH:mm');
                                setCurWritingTime(v)
                                setWritingTime(v);
                            }}
                            readOnly={appointmentState.type !== ''} />
                    </HStack>
                    <HStack gap={4} w={'full'}>
                        <Box minW={'20px'}>
                            <FaShare />
                        </Box>
                        <Text fontSize={16} minW={'92px'}>Sharing Time</Text>
                        <Spacer />
                        <TimePicker
                            use12Hours
                            format="h:mm a"
                            value={dayjs(curSharingTime, 'HH:mm')}
                            w={'10rem'}
                            onChange={e => {
                                const v = dayjs(e).format('HH:mm');
                                setCurSharingTime(v)
                                setSharingTime(v);
                            }} />
                    </HStack>
                    <HStack gap={4}>
                        <Box minW={'20px'}>
                            <FaQuestion />
                        </Box>

                        <Text fontSize={16} minW={'92px'}>Question :</Text>
                        <Text fontSize={16}>{data.question}</Text>
                    </HStack>
                </VStack>
            </CardBody>
            <CardFooter justifyContent={'center'}>
                {(() => {
                    if (appointmentState.type === '') {
                        return (
                            <HStack>
                                <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('write')}>Write</Button>
                                <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('video')}>Video</Button>
                                <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('audio')}>Audio</Button>
                                <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} colorScheme="red" onClick={onOpen}>Remove</Button>
                            </HStack>
                        )
                    }

                    if (appointmentState.type === 'write') {
                        if (!appointmentState.data.share) {
                            return (
                                <HStack>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('write')}>View</Button>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} colorScheme="blue" onClick={()=> {handleReadyShare('write')}}>Share</Button>
                                </HStack>
                            )
                        } else {
                            return (
                                <HStack>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} colorScheme="blue" onClick={()=> {handleReadyShare('write')}}>Share</Button>
                                    {/* <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('write')}>View</Button> */}
                                </HStack>
                            )
                        }
                    }

                    if (appointmentState.type === 'video') {
                        if (!appointmentState.data.share) {
                            return (
                                <HStack>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('video')}>Watch</Button>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} colorScheme="blue" onClick={()=> {handleReadyShare('video')}}>Share</Button>
                                </HStack>
                            )
                        } else {
                            return (
                                <HStack>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} colorScheme="blue" onClick={()=> {handleReadyShare('video')}}>Share</Button>
                                    {/* <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('video')}>Watch</Button> */}
                                </HStack>
                            )
                        }
                    }

                    if (appointmentState.type === 'audio') {
                        if (!appointmentState.data.share) {
                            return (
                                <HStack>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('audio')}>Listen</Button>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} colorScheme="blue" onClick={()=> {handleReadyShare('audio')}}>Share</Button>
                                </HStack>
                            )
                        } else {
                            return (
                                <HStack>
                                    <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} colorScheme="blue" onClick={()=> {handleReadyShare('audio')}}>Share</Button>
                                    {/* <Button size={{ base: 'sm', sm: 'sm', md: 'md', xl: 'md' }} onClick={() => handleGoto('audio')}>Listen</Button> */}
                                </HStack>
                            )
                        }
                    }
                })()}
            </CardFooter>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Remove Dialogue</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>Are you sure you want to delete this appointment?</ModalBody>
                    <ModalFooter>
                        <Button onClick={handleRemove} colorScheme="red">Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Card>
    );
}

export default NextAppointmentItem;