
import { Card, CardBody, CardHeader, VStack, useToast, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URLS } from "../../Constants";
import axios from "axios";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../ckeditor-styles.css'
function HowTo(props) {
    const [howto, setHowto] = useState({});
    const toast = useToast();
    useEffect(() => {
        const loadHowTo = async () => {
            try {
                const response = await axios.get(API_URLS.MATERIAL_HOWTO);

                if (response.data.success) {
                    setHowto(response.data.data);
                } else {
                    toast({
                        title: "How to Dialogue",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
        loadHowTo();


    }, [toast]);
    return (
        <Card minH={"600px"} w={'full'}>
            <CardHeader>
                {props.title}
            </CardHeader>
            <CardBody>
                <VStack align={'start'}>
                    <Text>Writing Letter</Text>
                    <Card w={'full'}>
                        <CardBody w={'full'}>
                            {howto[0] && howto[0].writingLetter && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={howto[0]['writingLetter']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }}
                                    disabled
                                />
                            )}
                        </CardBody>
                    </Card>
                    <Text>Sharing Letter</Text>
                    <Card w={'full'}>
                        <CardBody>
                            {howto[0] && howto[0].sharingLetter && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={howto[0]['sharingLetter']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }}
                                    disabled
                                />
                            )}</CardBody>
                    </Card>
                </VStack>
            </CardBody>
        </Card>
    )
}

export default HowTo;