import { Card, CardBody, CardHeader, VStack, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URLS } from "../../Constants";
import axios from "axios";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../ckeditor-styles.css'
function WEDS(props) {
    const [weds, setWeds] = useState({});
    const toast = useToast();
    useEffect(() => {
        const loadWeds = async () => {
            try {
                const response = await axios.get(API_URLS.MATERIAL_WEDS);
                if (response.data.success) {
                    setWeds(response.data.data);
                } else {
                    toast({
                        title: "Procedure (WEDS)",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
        loadWeds();


    }, [toast]);
    return (
        <Card minH={"600px"} w={'full'}>
            <CardHeader>
                {props.title}
            </CardHeader>
            <CardBody>
                <VStack align={'start'} w={'full'}>
                    <Text>W = Write</Text>
                    <Card w={'full'}>
                        <CardBody w={'full'}>
                            {weds[0] && weds[0].write && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={weds[0]['write']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }}
                                    disabled
                                />
                            )}
                        </CardBody>
                    </Card>
                    <Text>E = Exchange</Text>
                    <Card w={'full'}>
                        <CardBody w={'full'}>
                            {weds[0] && weds[0].exchange && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={weds[0]['exchange']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }}
                                    disabled
                                />
                            )}</CardBody>
                    </Card>
                    <Text>D = Dialogue</Text>
                    <Card w={'full'}>
                        <CardBody w={'full'}>
                            {weds[0] && weds[0].dialogue && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={weds[0]['dialogue']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }}
                                    disabled
                                />
                            )}</CardBody>
                    </Card>
                    <Text>S = Select</Text>
                    <Card w={'full'}>
                        <CardBody w={'full'}>
                            {weds[0] && weds[0].select && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={weds[0]['select']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }}
                                    disabled
                                />
                            )}</CardBody>
                    </Card>
                </VStack>
            </CardBody>
        </Card>
    )
}

export default WEDS;