
export const API_URLS = {
    LOGIN: `${process.env.REACT_APP_API_URL}/auth/login`,
    REGISTER: `${process.env.REACT_APP_API_URL}/auth/register`,
    CONTACT_UPDATE: `${process.env.REACT_APP_API_URL}/user/contact_update`,
    CONTACT_INFO: `${process.env.REACT_APP_API_URL}/user/contact`,
    APPT_UPDATE: `${process.env.REACT_APP_API_URL}/user/appointment_update`,
    APPT_INFO: `${process.env.REACT_APP_API_URL}/user/appointment`,
    TIMERS_UPDATE: `${process.env.REACT_APP_API_URL}/user/timers_update`,
    TIMERS_INFO: `${process.env.REACT_APP_API_URL}/user/timers`,
    WORDS_UPDATE: `${process.env.REACT_APP_API_URL}/user/words_update`,
    WORDS_INFO: `${process.env.REACT_APP_API_URL}/user/words`,
    SUBSCRIPTION: `${process.env.REACT_APP_API_URL}/user/subscription`,
    SEND_EMAIL: `${process.env.REACT_APP_API_URL}/user/send-email`,
    PASSWORD_RESET: `${process.env.REACT_APP_API_URL}/auth/reset_password`,
    PASSWORD_FORGOT: `${process.env.REACT_APP_API_URL}/auth/forgot_password`,

    MATERIAL_HOWTO: `${process.env.REACT_APP_API_URL}/material/get-howto`,
    MATERIAL_GLOSSARY: `${process.env.REACT_APP_API_URL}/material/get-glossary`,
    MATERIAL_PIM: `${process.env.REACT_APP_API_URL}/material/get-describing`,
    MATERIAL_WEDS: `${process.env.REACT_APP_API_URL}/material/get-procedure`,
    MATERIAL_RULES: `${process.env.REACT_APP_API_URL}/material/get-ground`,
    MATERIAL_FEELINGS: `${process.env.REACT_APP_API_URL}/material/get-feelings`,
    MATERIAL_VIDEOS: `${process.env.REACT_APP_API_URL}/material/get-video-link`,
    ABOUT: `${process.env.REACT_APP_API_URL}/material/get-about`,

    DIALOGUE_QUESTION_CATEGORY_LIST: `${process.env.REACT_APP_API_URL}/dialogue/question_category_list`,
    DIALOGUE_QUESTIONS_LIST: `${process.env.REACT_APP_API_URL}/appointment/get-category-question`,
    DIALOGUE_FEELING_CATEGORY_LIST: `${process.env.REACT_APP_API_URL}/material/feeling_category_list`,
    DIALOGUE_FEELINGS_LIST: `${process.env.REACT_APP_API_URL}/material/get-course-feeling`,

    DIALOGUE_GET_SEL_APPT_LETTER: `${process.env.REACT_APP_API_URL}/dialogue/get_sel_appointment_letter`,
    DIALOGUE_GET_SHARE_APPT_LETTER: `${process.env.REACT_APP_API_URL}/dialogue/get_share_appointment_letter`,
    DIALOGUE_GET_PRESHARE_APPT_LETTER: `${process.env.REACT_APP_API_URL}/dialogue/get_preshare_appointment_letter`,

    DIALOGUE_WRITE: `${process.env.REACT_APP_API_URL}/dialogue/write`,

    DIALOGUE_GET_SEL_APPT_VIDEO: `${process.env.REACT_APP_API_URL}/dialogue/get_sel_appointment_video`,
    DIALOGUE_GET_SHARE_APPT_VIDEO: `${process.env.REACT_APP_API_URL}/dialogue/get_share_appointment_video`,
    DIALOGUE_GET_PRESHARE_APPT_VIDEO: `${process.env.REACT_APP_API_URL}/dialogue/get_preshare_appointment_video`,

    DIALOGUE_GET_SEL_APPT_AUDIO: `${process.env.REACT_APP_API_URL}/dialogue/get_sel_appointment_audio`,
    DIALOGUE_GET_SHARE_APPT_AUDIO: `${process.env.REACT_APP_API_URL}/dialogue/get_share_appointment_audio`,
    DIALOGUE_GET_PRESHARE_APPT_AUDIO: `${process.env.REACT_APP_API_URL}/dialogue/get_preshare_appointment_audio`,

    DIALOGUE_ADD_VIDEO: `${process.env.REACT_APP_API_URL}/dialogue/add-video`,

    DIALOGUE_ADD_AUDIO: `${process.env.REACT_APP_API_URL}/dialogue/add-audio`,

    DIALOGUE_ALL_APPTS: `${process.env.REACT_APP_API_URL}/appointment/all-appointments`,

    DIALOGUE_CHECK_COUNT: `${process.env.REACT_APP_API_URL}/appointment/check-count`,

    DIALOGUE_CREATE: `${process.env.REACT_APP_API_URL}/appointment/create`,
    DIALOGUE_APPTS: `${process.env.REACT_APP_API_URL}/appointment/appointments`,

    DIALOGUE_CHECK_APPT_STATE: `${process.env.REACT_APP_API_URL}/appointment/check_appointment_state`,

    DIALOGUE_REMOVE_APPT: `${process.env.REACT_APP_API_URL}/appointment/remove_appointment`,

    DIALOGUE_SHARE_APPT: `${process.env.REACT_APP_API_URL}/appointment/share_appointment`,
    DIALOGUE_PRESHARE_APPT: `${process.env.REACT_APP_API_URL}/appointment/preshare_appointment`,
    DIALOGUE_GET_SHARE_APPT: `${process.env.REACT_APP_API_URL}/appointment/get_share_appointment`,
    DIALOGUE_GET_PRESHARE_APPT: `${process.env.REACT_APP_API_URL}/appointment/get_preshare_appointment`,

    DIALOGUE_SAVE_DRAFT: `${process.env.REACT_APP_API_URL}/dialogue/save_draft`
}