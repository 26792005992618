import axios from "axios";
import { API_URLS } from "../../Constants";
import { AuthContext } from "../../providers/AuthProvider";
import { DialogueContext } from "../../providers/DialogueProvider";
import { Container, HStack, Textarea, useToast, Text, Progress, Box, Spacer, VStack } from "@chakra-ui/react";
import { useContext, useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";

const DialogueLetter = forwardRef((props, ref) => {
    const toast = useToast();
    const { cookieAlive } = useContext(AuthContext);
    const { content, setContent, addFlag, feelingData, makeTimeEllapsed, setMakeTimeEllapsed } = useContext(DialogueContext);
    const [limitTime, setLimitData] = useState(0);
    const [sortedWriteAlarms, setSortedWriteAlarms] = useState([]);
    const [timeIsUp, setTimeIsUp] = useState(false);
    const isMounted = useRef(true);

    const timerRef = useRef(null);
    useImperativeHandle(ref, () => ({
        stopTimer() {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        }
    }));

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Get about the time information
    useEffect(() => {
        const loadTimers = async () => {
            if (!cookieAlive()) return;

            try {
                const response = await axios.post(API_URLS.TIMERS_INFO, {
                    email: cookieAlive(),
                });

                if (response.data.success) {
                    const timers = response.data.data;
                    if (timers.write_time) {
                        setLimitData(parseInt(timers.write_time) * 60);
                    }

                    if (timers.write_alarm && timers.write_alarm.length > 0) {
                        const sortedAlarms = timers.write_alarm.sort((a, b) => {
                            const timeA = a.unit === 'm' ? a.time * 60 : a.time;
                            const timeB = b.unit === 'm' ? b.time * 60 : b.time;
                            return timeA - timeB;
                        });

                        let tempSortedAlarmData = [];
                        for (let i = 0; i < sortedAlarms.length; i++) {
                            if (sortedAlarms[i].enabled === true) {
                                if (sortedAlarms[i].unit === "s") {
                                    tempSortedAlarmData.push(sortedAlarms[i].time);
                                } else {
                                    tempSortedAlarmData.push(sortedAlarms[i].time * 60);
                                }
                            }
                        }

                        setSortedWriteAlarms(tempSortedAlarmData);
                    }
                } else {
                    if (isMounted.current) {
                        toast({
                            title: "Timer Information",
                            description: `${response.data.message}.`,
                            bg: 'blue',
                            color: 'white',
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadTimers();
    }, [cookieAlive, toast]);

    // Set the timer event
    useEffect(() => {

        if (limitTime === 0 || !addFlag) return; // Check addFlag
        const interval = setInterval(() => {
            setMakeTimeEllapsed(prevTime => {
                if (prevTime >= limitTime) {
                    clearInterval(interval);

                    if (isMounted.current) {
                        setTimeIsUp(true);

                        // Move toast call to a useEffect
                        setTimeout(() => {
                            toast({
                                title: "Time's up!",
                                description: "Writing time is up.",
                                duration: 3000,
                                isClosable: true,
                                bg: 'blue',
                                color: 'white'
                            });
                        }, 0);
                    }

                    return 0;
                }

                const newTime = prevTime + 1;
                if (sortedWriteAlarms.includes(newTime.toString())) {
                    const audio = new Audio("./audio/alarm.mp3");
                    audio.play();
                }
                return newTime;
            });
        }, 1000);

        timerRef.current = interval;

        return () => clearInterval(interval);


    }, [limitTime, sortedWriteAlarms, toast, addFlag]); // Add addFlag to dependencies

    const handleChangeLetter = (event) => {
        setContent(event.target.value);
    }

    return (
        <Box w={'full'} h={'full'}>
            <HStack gap={3} w={'full'} h={'300px'} display="flex" flexDirection="column">
                <Text fontSize='md' w={'full'} textAlign={'center'} fontWeight={600}>
                    {feelingData && feelingData.length > 0 && feelingData.map(feeling => feeling.text).join(', ')}
                </Text>
                <Textarea
                    h={'100%'}
                    onChange={handleChangeLetter}
                    value={content}
                    isDisabled={timeIsUp || !addFlag}
                    w={'full'}
                >
                </Textarea>
                <VStack w={'full'}>
                    <HStack>
                    <Text>{`${String(Math.floor((limitTime - makeTimeEllapsed) / 60)).padStart(2, '0')}:${String((limitTime - makeTimeEllapsed) % 60).padStart(2, '0')}`}{`/`}{`${String(Math.floor(limitTime / 60)).padStart(2, '0')}:${String(limitTime % 60).padStart(2, '0')} mins`}</Text>
                    </HStack>

                    <HStack w={'full'}>
                        <Text fontSize='md'>Time: </Text>
                        <Progress w={'full'} max={limitTime} value={makeTimeEllapsed} colorScheme="blue" size='xs' />

                    </HStack>
                </VStack>

            </HStack>
        </Box>
    )
});

export default DialogueLetter;