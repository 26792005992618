import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Text,
	FormControl,
	FormLabel,
	GridItem,
	HStack,
	Input,
	RadioGroup,
	Radio,
	SimpleGrid,
	VStack,
	useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { FaSave } from "react-icons/fa";
import { useCookies } from 'react-cookie';
import { useContext, useEffect, useState } from "react";

import { API_URLS } from "../../Constants";
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";

function Words() {
	const toast = useToast();
	const [cookies, setCookie] = useCookies();
	const { cookieAlive } = useContext(AuthContext);
	const { words, setWords } = useContext(AppContext);
	const [isSaving, setIsSaving] = useState(false);
	const validateInfo = (contact) => {
		if (!contact) {
			return true;
		} else {
			const keys = Object.keys(contact);
			for (let i = 0; i < keys.length; i++) {
				if (!contact[keys[i]] || contact[keys[i]] === "") {
					return false;
				}
			}
			return true;
		}
	};

	useEffect(() => {
		const loadWords = async () => {
			if (!cookieAlive()) return;
			try {
				const response = await axios.post(API_URLS.WORDS_INFO, {
					email: cookieAlive(),
				});
				if (response.data.success) {
					const _words = response.data.data;
					setWords(_words);
				} else {
					toast({
						title: "Email Wordings",
						description: `${response.data.message}.`,
						bg: 'blue',
						color: 'white',
						duration: 3000,
						isClosable: true,
					});
				}
			} catch (e) {
				console.error(e);
			}
		};
		loadWords();
	}, [toast]);

	const handleSave = async () => {
		try {
			if (!validateInfo(words)) {
				toast({
					title: "Email Wordings",
					description: `Please check the input fields.`,
					duration: 3000,
					isClosable: true,
					bg: 'blue',
					color: 'white'
				});
				return;
			}
			setIsSaving(true);
			const response = await axios.post(API_URLS.WORDS_UPDATE, { words, email: cookieAlive() });

			if (response.data.success) {
				toast({
					title: "Email Wordings",
					description: `${response.data.message}.`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});

				setCookie("dd_words_appointment", words?.appointment);
				setCookie("dd_words_done", words?.done);
				setCookie("dd_words_share", words?.share);
			} else {
				toast({
					title: "Email Wordings",
					description: `${response.data.message}.`,
					bg: 'blue',
					color: 'white',
					duration: 3000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Card minH={"600px"} w={'full'}>
			<CardBody>
				<VStack gap={2}>
					<Text>Email</Text>
					<FormControl w={'full'}>
						<FormLabel>Send writing appointment email for</FormLabel>
						<RadioGroup
							value={words['email_appointment']}
							onChange={(v) => {
								setWords({
									...words,
									email_appointment: v
								})
							}}
							w={'full'}>
							<HStack w={'full'}>
								<Radio value="0">Nobody</Radio>
								<Radio value="1">Me</Radio>
								<Radio value="2">Spouse</Radio>
								<Radio value="3">Both</Radio>
							</HStack>
						</RadioGroup>
					</FormControl>
					<FormControl w={'full'}>
						<FormLabel>Wording for writing appointment</FormLabel>
						<Input
							type="text"
							placeholder="Here is our question..."
							onChange={(e) => {
								setWords({
									...words,
									appointment: e.target.value
								})
							}}
							defaultValue={words['appointment']}
						/>
					</FormControl>
					<FormControl w={'full'}>
						<FormLabel>Send writing completed email for</FormLabel>
						<RadioGroup
							value={words['email_done']}
							onChange={(v) => {
								setWords({
									...words,
									email_done: v
								})
							}}
							w={'full'}>
							<HStack w={'full'}>
								<Radio value="0">Nobody</Radio>
								<Radio value="1">Me</Radio>
								<Radio value="2">Spouse</Radio>
								<Radio value="3">Both</Radio>
							</HStack>
						</RadioGroup>
					</FormControl>
					<FormControl w={'full'}>
						<FormLabel>Wording for writing is completed</FormLabel>
						<Input
							type="email"
							placeholder="Hey, I just wanted you to know..."
							onChange={(e) => {
								setWords({
									...words,
									done: e.target.value
								})
							}}
							defaultValue={words['done']}
						/>
					</FormControl>
					<FormControl w={'full'}>
						<FormLabel>Send sharing appointment for</FormLabel>
						<RadioGroup
							value={words['email_share']}
							onChange={(v) => {
								setWords({
									...words,
									email_share: v
								})
							}}
							w={'full'}>
							<HStack w={'full'}>
								<Radio value="0">Nobody</Radio>
								<Radio value="1">Me</Radio>
								<Radio value="2">Spouse</Radio>
								<Radio value="3">Both</Radio>
							</HStack>
						</RadioGroup>
					</FormControl>
					<FormControl w={'full'}>
						<FormLabel>Wording for sharing appointment</FormLabel>
						<Input
							type="text"
							placeholder="Hey, I just wanted you to know..."
							onChange={(e) => {
								setWords({
									...words,
									share: e.target.value
								})
							}}
							defaultValue={words['share']}
						/>
					</FormControl>
				</VStack>
			</CardBody>
			<CardFooter>
				<Button
					w="full"
					colorScheme="blue"
					leftIcon={<FaSave />}
					onClick={handleSave}
					isLoading={isSaving}
				>
					Save
				</Button>
			</CardFooter>
		</Card>
	);
}

export default Words;
