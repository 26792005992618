import React, { createContext, useState } from 'react';
import {useCookies} from "react-cookie";
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['dd_token', 'dd_user_id', 'dd_user_email', 'dd_spouse_email', 'dd_user_level']);

    const saveUserInfoCookie = ({id, email, spouseEmail, token, level, words_appointment, words_done, words_share}) => {
        setCookie("dd_token", token);
        setCookie("dd_user_id", id);
        // setCookie("dd_user_email", email);
        setCookie("dd_spouse_email", spouseEmail);
        setCookie("dd_user_level", level);
        setCookie("dd_words_appointment", words_appointment);
        setCookie("dd_words_done", words_done);
        setCookie("dd_words_share", words_share);
    };

    const logout = () => {
        setUser(null);
        Object.keys(cookies).forEach(cookieName => removeCookie(cookieName));
    };

    const cookieAlive = ()=> {
        if (cookies['dd_user_email'])
            return cookies['dd_user_email'];
        else
            return false;
    }

    return (
        <AuthContext.Provider value={{ user, saveUserInfoCookie, logout, cookieAlive }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
