import { Box, Card, CardBody, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URLS } from "../Constants";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../ckeditor-styles.css'
import axios from "axios";
function About() {
    const [about, setAbout] = useState("");
    const toast = useToast();

    useEffect(() => {
        const loadAbout = async () => {
            try {
                const response = await axios.get(API_URLS.ABOUT);
                if (response.data.success) {
                    setAbout(response.data.data[0]['about']);
                } else {
                    toast({
                        title: "About",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
        loadAbout();


    }, [toast]);

    return (
        <Box p={4}>
            <Card>
                <CardBody zIndex={0}>
                    {about && (
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                plugins: [
                                    Essentials, Bold, Italic, Paragraph, Font, 
                                    Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                    List, Indent, IndentBlock, Alignment, Link, AutoLink
                                  ],
                            }
                            }
                            data={about}
                            disabled
                        />
                    )}
                </CardBody>
            </Card>
        </Box>
    );
}

export default About;
