import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Grid,
    GridItem,
    VStack,
    useMediaQuery
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    FaBriefcase,
    FaClock,
    FaEnvelope,
    FaKey,
    FaUser,
} from "react-icons/fa";
import ContactInfo from "../components/settings/ContactInfo";
import { AuthContext } from "../providers/AuthProvider";
import Appointment from "../components/settings/Appointment";
import Timers from "../components/settings/Timers";
import Words from "../components/settings/Words";
import Password from "../components/settings/Password";
function Settings() {
    const navigate = useNavigate();
    const { cookieAlive } = useContext(AuthContext);
    const [contentOpened, setContentOpened] = useState(1);
    const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
    const handleContentHeader = (title, itemIdx) => {
        setContentOpened(itemIdx);
    };

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }
    }, [cookieAlive, navigate]);

    return (
        <Box w={"full"}>
            <Grid
                templateRows={"repeat(1, 1fr)"}
                templateColumns={isLargerThan900 ? "repeat(4, 1fr)" : "repeat(1, 1fr)"}
                gap={isLargerThan900 ? 4 : 0}
                mx={4}
            >
                <GridItem rowSpan={1} colSpan={1} w={"full"} mb={isLargerThan900 ? 0 : 4}>
                    <Card w={"full"} minH={"600px"}>
                        <CardHeader>Settings</CardHeader>
                        <CardBody>
                            <VStack w={"full"} align={"start"}>
                                <Button
                                    leftIcon={<FaUser />}
                                    colorScheme={
                                        contentOpened === 1 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            1
                                        )
                                    }
                                >
                                    Contact Information
                                </Button>
                                <Button
                                    leftIcon={<FaBriefcase />}
                                    colorScheme={
                                        contentOpened === 2 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            2
                                        )
                                    }
                                >
                                    Appointments
                                </Button>
                                <Button
                                    leftIcon={<FaClock />}
                                    colorScheme={
                                        contentOpened === 3 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            3
                                        )
                                    }
                                >
                                    Timers
                                </Button>
                                <Button
                                    leftIcon={<FaEnvelope />}
                                    colorScheme={
                                        contentOpened === 4 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            4
                                        )
                                    }
                                >
                                    Emails
                                </Button>
                            </VStack>
                        </CardBody>
                        <CardHeader>Security</CardHeader>
                        <CardBody>
                            <Button
                                leftIcon={<FaKey />}
                                colorScheme={
                                    contentOpened === 5 ? "blue" : "gray"
                                }
                                w={"full"}
                                onClick={(e) =>
                                    handleContentHeader(e.target.innerText, 5)
                                }
                            >
                                Reset Password
                            </Button>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem rowSpan={1} colSpan={3} w={"full"}>
                    {contentOpened === 1 && <ContactInfo />}
                    {contentOpened === 2 && <Appointment />}
                    {contentOpened === 3 && <Timers />}
                    {contentOpened === 4 && <Words />}
                    {contentOpened === 5 && <Password />}
                </GridItem>
            </Grid>
        </Box>
    );
}

export default Settings;
