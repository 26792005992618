import {
	Button,
	Card,
	CardBody,
	CardHeader,
	HStack,
	VStack,
	Text,
	CardFooter,
	useToast,
	useMediaQuery,
	Grid,
	Box,
	GridItem,
} from "@chakra-ui/react";
import axios from 'axios';
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { FaSave } from "react-icons/fa";
import { useContext, useState, useEffect } from "react";
import { API_URLS } from "../../Constants";
import { timeFormat, extractTimeArray } from "../../utils/utils";
import moment from "moment";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
function Appointment() {
	const toast = useToast();
	const [isSaving, setIsSaving] = useState(false);
	const { cookieAlive } = useContext(AuthContext);
	const { appointment, setAppointment } = useContext(AppContext);
	const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
	const [writingTime, setWritingTime] = useState("12:00");
	const [sharingTime, setSharingTime] = useState("21:00");

	const [selectedWeekDayButtons, setSelectedWeekDayButtons] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	const [selectedDays, setSelectedDays] = useState([]);

	const handleClickWeekDay = (_weekday) => {
		const isPressed = selectedWeekDayButtons[_weekday];
		const newSelectedDays = [...selectedDays];
		if (isPressed) {
			if (selectedDays.indexOf(_weekday) !== -1) {
				newSelectedDays.splice(selectedDays.indexOf(_weekday), 1);
				setSelectedDays(newSelectedDays);
			}

			if (newSelectedDays.length === 1) {
				const writingKey = `${weekDays[_weekday]}_writing`;
				const sharingKey = `${weekDays[_weekday]}_sharing`;
				const writingInfo = appointment[writingKey];
				const sharingInfo = appointment[sharingKey];
				setWritingTime(timeFormat(writingInfo));
				setSharingTime(timeFormat(sharingInfo));
			}
		} else {
			if (newSelectedDays.length === 0) {
				const writingKey = `${weekDays[_weekday]}_writing`;
				const sharingKey = `${weekDays[_weekday]}_sharing`;
				const writingInfo = appointment[writingKey];
				const sharingInfo = appointment[sharingKey];
				setWritingTime(timeFormat(writingInfo));
				setSharingTime(timeFormat(sharingInfo));
				newSelectedDays.push(_weekday);
			} else {
				newSelectedDays.push(_weekday);
				setWritingTime(moment().format("HH:mm"));
				setSharingTime(moment().format("HH:mm"));
			}

			setSelectedDays(newSelectedDays);
		}

		const newSelectedWeekDayButtons = [...selectedWeekDayButtons];
		newSelectedWeekDayButtons[_weekday] = !isPressed;
		setSelectedWeekDayButtons(newSelectedWeekDayButtons);
	};

	const handleSave = async () => {
		try {
			setIsSaving(true);
			const response = await axios.post(API_URLS.APPT_UPDATE, { appointments: appointment, email: cookieAlive() });
			if (response.data.success) {
				toast({
					title: "Appointment Defaults",
					description: `${response.data.message}.`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Appointment Defaults",
					description: `${response.data.message}.`,
					bg: 'blue',
                color: 'white',
					duration: 3000,
					isClosable: true,
				});
			}

		} catch (error) {
			console.error(error);
		} finally {
			setIsSaving(false);
		}
	}

	const handleChangeWritingTime = (e) => {
		const v = dayjs(e).format('HH:mm');
		setWritingTime(v);
		const newSharingTime = (moment(v, 'HH:mm').add(10, 'minutes')).format("HH:mm");
		setSharingTime(newSharingTime);
		if (selectedDays.length === 0) {
			return;
		}
		const newAppointment = { ...appointment };
		for (let i = 0; i < selectedDays.length; i++) {
			const selectedDay = selectedDays[i];
			newAppointment[`${weekDays[selectedDay]}_writing`] = extractTimeArray(v);
			newAppointment[`${weekDays[selectedDay]}_sharing`] = extractTimeArray(newSharingTime);
		}
		setAppointment(newAppointment);
	}

	const handleChangeSharingTime = (e) => {
		const v = dayjs(e).format('HH:mm');
		setSharingTime(v);
		if (selectedDays.length === 0) {
			return;
		}
		const newAppointment = { ...appointment };
		for (let i = 0; i < selectedDays.length; i++) {
			const selectedDay = selectedDays[i];
			newAppointment[`${weekDays[selectedDay]}_sharing`] = extractTimeArray(v);
		}
		setAppointment(newAppointment);
	}

	useEffect(() => {
		const loadAppointment = async () => {
			if (!cookieAlive()) return;
			try {
				const response = await axios.post(API_URLS.APPT_INFO, {
					email: cookieAlive(),
				});
				if (response.data.success) {
					const appointment = response.data.data;
					setAppointment(appointment);
				} else {
					toast({
						title: "Appointment Defaults",
						description: `${response.data.message}.`,
						bg: 'blue',
                color: 'white',
						duration: 3000,
						isClosable: true,
					});
				}
			} catch (e) {
				console.error(e);
			}
		};
		loadAppointment();
	}, [cookieAlive, setAppointment, toast]);

	return (
		<Card minH={"600px"} w={'full'}>
			<CardHeader>
				Appointment Defaults
			</CardHeader>
			<CardBody>
				<Grid templateColumns={isLargerThan600 ? "repeat(4, 1fr)" : "repeat(1, 1fr)"} templateRows={isLargerThan600 ? "repeat(1, 1fr)" : "repeat(1, 1fr)"} gap={6}>
					<GridItem rowSpan={1} colSpan={1}></GridItem>
					<GridItem rowSpan={1} colSpan={3}>
						<VStack w={'full'} alignItems={'flex-start'}>
							<Text fontSize={18} fontWeight={700}>Instructions</Text>
							<Text fontSize={18} fontWeight={600}>{`1) Click the Day (or multiple days)`}</Text>
							<Text fontSize={18} fontWeight={600}>{`2) Set the Time for Writing and Sharing`}</Text>
							<Text fontSize={18} fontWeight={600}>{`3) Click Save`}</Text>
						</VStack>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1} gap={6}>
						<VStack gap={6} align={'start'}>
							<Text lineHeight={'40px'}>Set Time</Text>
							<HStack flexDirection={isLargerThan600 ? 'row' : 'column'} gap={isLargerThan600 ? '4' : '2'} w={'full'}>
								<Box w={isLargerThan600 ? '30%' : 'full'}>
									<Text noOfLines={1} w={'full'}>Writing</Text>
								</Box>
								<TimePicker use12Hours format="h:mm a" w={'150px'} value={dayjs(writingTime, "HH:mm")} onChange={handleChangeWritingTime} />
								
							</HStack>
							<HStack flexDirection={isLargerThan600 ? 'unset' : 'column'} gap={isLargerThan600 ? '4' : '2'} w={'full'}>
								<Box w={isLargerThan600 ? '30%' : 'full'}>
									<Text noOfLines={1} w={'full'}>Sharing</Text>
								</Box>
								<TimePicker use12Hours format="h:mm a" w={'150px'} value={dayjs(sharingTime, "HH:mm")} onChange={handleChangeSharingTime}/>
							</HStack>
						</VStack>
					</GridItem>
					<GridItem rowSpan={1} colSpan={3} gap={6}>
						<VStack gap={6} align={'start'}>
							<HStack w={'full'} justifyContent={'space-between'} lineHeight={'40px'}>
								{weekDays.map((weekDay, index) => (
									<Button
										key={index}
										{...(selectedWeekDayButtons[index] ? { bg: "#3182ce", color: 'white' } : {})}
										onClick={(e) => handleClickWeekDay(index)}
										width={isLargerThan600 ? '80px' : '250px'}
										_hover={{
											bg: "#3182ce", color: 'white'
										}}
									>
										{weekDay}
									</Button>
								))}
							</HStack>
							<HStack w={'full'} justifyContent={'space-between'} lineHeight={'40px'}>
								{Object.keys(appointment).map((item, idx) => (
									idx % 2 === 0 && (
										<Text key={idx}>{timeFormat(appointment[item], true)}</Text>
									)
								))}
							</HStack>
							<HStack w={'full'} justifyContent={'space-between'} lineHeight={'40px'}>
								{Object.keys(appointment).map((item, idx) => (
									idx % 2 === 1 && (
										<Text key={idx}>{timeFormat(appointment[item], true)}</Text>
									)
								))}
							</HStack>
						</VStack>
					</GridItem>

				</Grid>
			</CardBody>
			<CardFooter>
				<Button
					w="full"
					colorScheme="blue"
					leftIcon={<FaSave />}
					onClick={handleSave}
					isLoading={isSaving}
				>
					Save
				</Button>
			</CardFooter>

		</Card>
	);
}

export default Appointment;
