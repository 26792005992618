import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { VStack, Button, useToast, HStack, Card, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import moment from "moment";
import { API_URLS } from "../Constants";
import { AuthContext } from "../providers/AuthProvider";
import HistoryItem from "../components/history/historyItem";
import { AppContext } from "../providers/AppProvider";
import { useCookies } from "react-cookie";
import { DatePicker } from "antd";
import dayjs from "dayjs";

function History() {
	const navigate = useNavigate();
	const toast = useToast();
	const [curType, setCurtype] = useState("");
	const { cookieAlive } = useContext(AuthContext);
	const [appointment, setAppointment] = useState(null);
	const [curDate, setCurDate] = useState(moment().format("YYYY-MM-DD"));
	const [cookies, setCookie] = useCookies();
	const { contactInfo, setContactInfo } = useContext(AppContext);
	const [dateOpen, setDateOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState("husband");

	useEffect(() => {
		const loadContact = async () => {
			if (!cookieAlive()) return;
			try {
				const response = await axios.post(API_URLS.CONTACT_INFO, {
					email: cookieAlive(),
				});
				if (response.data.success) {
					const contact = response.data.data.contact;
					setContactInfo(contact);
				} else {
					toast({
						title: "Contact Information",
						description: `${response.data.message}.`,
						bg: 'blue',
						color: 'white',
						duration: 3000,
						isClosable: true,
					});
				}
			} catch (e) {
				console.error(e);
			}
		};
		loadContact();
	}, [cookieAlive, toast]);

	useEffect(() => {
		if (!cookieAlive()) {
			navigate("/login");
		}

		handleHusband();

	}, [cookieAlive, navigate]);

	const getAppointment = async (type) => {
		try {
			const response = await axios.post(API_URLS.DIALOGUE_GET_SHARE_APPT, {
				date: curDate,
				type: type,
				email: cookies['dd_user_email']
			});
			if (response.data.data) {
				setAppointment(response.data.data);
			} else {
				toast({
					title: "History",
					description: `${response.data.message}.`,
					duration: 3000,
					isClosable: true,
					bg: 'blue',
					color: 'white'
				});
				setAppointment({});
			}
		} catch (e) {
			console.error(e);
		}
	}

	const handleHusband = useCallback(() => {
		setSelectedRole('husband');
		if (contactInfo['hEmail'] === '') {
			toast({
				title: "History",
				description: `You need to login again.`,
				duration: 3000,
				isClosable: true,
				bg: 'blue',
				color: 'white'
			});
		} else {
			if (cookies['dd_h_email'] === cookies['dd_user_email']) {
				getAppointment('master');
				setCurtype('master');
			} else if (cookies['dd_w_email'] === cookies['dd_user_email']) {
				getAppointment('spouse');
				setCurtype('spouse');
			}
		}

	}, [contactInfo]);

	const handleWife = useCallback(() => {
		setSelectedRole('wife');
		if (cookies['dd_w_email'] === '') {
			toast({
				title: "History",
				description: `You need to login again.`,
				duration: 3000,
				isClosable: true,
				bg: 'blue',
				color: 'white'
			});
		} else {
			if (cookies['dd_w_email'] === cookies['dd_user_email']) {
				getAppointment('master');
				setCurtype('master');
			} else if (cookies['dd_h_email'] === cookies['dd_user_email']) {
				getAppointment('spouse');
				setCurtype('spouse');
			}
		}
	}, [contactInfo]);

	return (
		<VStack spacing={4} p={8}>
			<VStack w={'full'} maxW={'665px'}>
				<Button colorScheme="blue" w={'full'} position={'relative'} onClick={() => {
					setDateOpen(true);
				}}>
					{curDate}
				</Button>
				<DatePicker
					id="historyDatePicker"
					format={"YYYY-MM-DD"}
					maxDate={dayjs()}
					style={{ fontSize: '1rem', width: '100%', height: '1px', textAlign: 'center', visibility: 'hidden' }}
					open={dateOpen}
					onChange={(e) => {
						setDateOpen(p => !p);
						setCurDate(dayjs(e).format('YYYY-MM-DD'));
						setAppointment(null);
					}} />
				<Card w={'full'}>
					<CardHeader>
						<HStack justifyContent={'center'} w={'full'}>
							<Button colorScheme={selectedRole === 'husband' ? 'blue' : 'gray'} px={8} onClick={e => { handleHusband() }}>
								Husband
							</Button>
							<Button colorScheme={selectedRole === 'wife' ? 'blue' : 'gray'} px={8} onClick={e => { handleWife() }}>
								Wife
							</Button>
						</HStack>
					</CardHeader>
					<CardBody>
						{appointment && <HistoryItem data={appointment} type={curType} />}
					</CardBody>
				</Card>

			</VStack>
		</VStack>
	);
}

export default History;
