import React from "react";
import { Box } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Settings from "../pages/Settings";
import About from "../pages/About";
import Pricing from "../pages/Pricing";
import Material from "../pages/Material";

import History from "../pages/History";
import CreateAppointment from "../pages/CreateAppointment";
import WriteDialogue from "../pages/WriteDialogue";
import ForgotPassword from "../pages/ForgotPassword";
import VideoDialogue from "../pages/VideoDialogue";
import AudioDialogue from "../pages/AudioDialogue";
import ShareDialogue from "../pages/ShareDialogue";

function Body() {
	return (
		<Box
			className="App-body"
			minH={700}
		>
			<Routes>
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/login"
					element={<Login />}
				/>
				<Route
					path="/register"
					element={<Register />}
				/>
				<Route
					path="/forgotpwd"
					element={<ForgotPassword />}
				/>
				<Route
					path="/settings"
					element={<Settings />}
				/>
				<Route
					path="/history"
					element={<History />}
				/>
				<Route
					path="/about"
					element={<About />}
				/>
				<Route
					path="/upgrade"
					element={<Pricing />}
				/>
				<Route
					path="/material"
					element={<Material />}
				/>
				<Route
					path="/create"
					element={<CreateAppointment/>}
				/>
				<Route
					path="/write"
					element={<WriteDialogue/>}
				/>
				<Route
					path="/video"
					element={<VideoDialogue/>}
				/>
				<Route
					path="/audio"
					element={<AudioDialogue/>}
				/>
				<Route
					path="/share"
					element={<ShareDialogue/>}
					/>
			</Routes>
		</Box>
	);
}

export default Body;