import {
    Box,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepTitle,
    StepDescription,
    StepIcon,
    StepNumber,
    StepSeparator,
    VStack,
    useSteps,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    HStack,
    Button,
    Spacer,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Heading,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { API_URLS } from "../Constants";
import { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { DialogueContext } from "../providers/DialogueProvider";
import DialogueEmotion from "../components/dialogue/dialogue-emotion";
import DialogueCategory from "../components/dialogue/dialogue-category";
import DialogueLetter from "../components/dialogue/dialogue-letter";

function WriteDialogue() {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        onNext,
        onPrev,
        emotion,
        feelingData,
        content,
        addFlag,
        setEmotion,
        setFeelingData,
        setContent,
        setAddFlag,
        selDialogId,
        dialogueDate,
        writingTime,
        sharingTime
    } = useContext(DialogueContext);
    const { cookieAlive } = useContext(AuthContext);
    const [cookies, setCookie] = useCookies();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const steps = [
        {
            title: "Emotion",
            description: "Choose Emotion Category",
        },
        {
            title: "Feelings",
            description: "Choose Feelings (Max 3)",
        },
        {
            title: "Letter",
            description: "Write Dialogue Letter",
        },
    ];
    const dialogueLetterRef = useRef(null);

    const stopDialogueLetterTimer = () => {
        if (dialogueLetterRef.current) {
            dialogueLetterRef.current.stopTimer();
        }
    };

    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    });

    useEffect(() => {
        const getCheckExistLetter = async () => {
            setEmotion("");
            setFeelingData([]);
            setContent("");

            if (selDialogId) {
                const response = await axios.post(API_URLS.DIALOGUE_GET_SEL_APPT_LETTER, {
                    appointId: selDialogId,
                    userEmail: cookies["dd_user_email"],
                });

                if (response.data.draft) {
                    setAddFlag(true);
                    setEmotion(response.data.draft.emotion);
                    setFeelingData(response.data.draft.feelingData);
                    setContent(response.data.draft.content);
                    setActiveStep(2);
                } else {
                    if (response.data.dialogue && response.data.dialogue._id) {
                        setAddFlag(false);
                        setEmotion(response.data.dialogue.emotion);
                        setFeelingData(response.data.dialogue.feelings);
                        setContent(response.data.dialogue.letter);
                    } else {
                        setAddFlag(true);
                    }
                }
            }
        };
        getCheckExistLetter();
    }, [cookies, selDialogId, setAddFlag, setEmotion, setFeelingData, setContent]);

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        if (!selDialogId) {
            navigate("/");
        }
    }, [cookieAlive, selDialogId, navigate]);

    const handlePrev = () => {
        let step = activeStep;
        const prevResult = onPrev(step);
        if (!prevResult.result) {
            toast({
                title: "Write Dialogue",
                description: `${prevResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: 'blue',
                color: 'white'
            });
            return;
        }
        if (activeStep - 1 < 1) step = 1;
        else step = activeStep - 1;
        setActiveStep(step);
    };

    const handleNext = () => {
        let step = activeStep;
        const nextResult = onNext(step);
        if (!nextResult.result) {
            toast({
                title: "Write Dialogue",
                description: `${nextResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: 'blue',
                color: 'white'
            });
            return;
        }
        if (activeStep + 1 > 3) onOpen();
        else step = activeStep + 1;

        setActiveStep(step);
    };

    const handleSaveDraft = async () => {

        try {
            const response = await axios.post(API_URLS.DIALOGUE_SAVE_DRAFT, {
                emotion: emotion,
                feelingData: feelingData,
                content: content,
                appointmentId: selDialogId,
                userEmail: cookies['dd_user_email']
            });
            if (response.data.success) {
                toast({
                    title: "Write Dialogue",
                    description: `${response.data.message}.`,
                    duration: 3000,
                    isClosable: true,
                    bg: 'blue',
                    color: 'white'
                });
                navigate('/');
            } else {
                toast({
                    title: "Write Dialogue",
                    description: `${response.data.message}.`,
                    duration: 3000,
                    isClosable: true,
                    bg: 'blue',
                    color: 'white'
                });
            }
        } catch (e) {
            toast({
                title: "Write Dialogue",
                description: `${e.message}.`,
                duration: 3000,
                isClosable: true,
                bg: 'blue',
                color: 'white'
            });
        }
    }

    const handleSaveDialogue = async () => {
        stopDialogueLetterTimer();

        try {
            const response = await axios.post(API_URLS.DIALOGUE_WRITE, {
                emotion: emotion,
                feelings: feelingData,
                letter: content,
                appointmentId: selDialogId,
                appointmentDate: dialogueDate,
                userId: cookies["dd_user_id"],
                userEmail: cookies["dd_user_email"],
                spouseEmail: cookies["dd_spouse_email"],
            });

            if (response.data.success) {
                sendEmail();

                onClose();
                navigate('/');
            } else {
                toast({
                    title: "Write Dialogue",
                    description: `${response.data.message}.`,
                    bg: 'blue',
                    color: 'white',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: "Write Dialogue",
                description: `${e.message}.`,
                bg: 'blue',
                color: 'white',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const sendEmail = async () => {
        try {
            const response = await axios.post(API_URLS.SEND_EMAIL, {
                type: "write",
                ownerEmail: cookies["dd_user_email"],
                email: cookies["dd_spouse_email"],
                date: dialogueDate,
                writingTime: writingTime,
                sharingTime: sharingTime,
                content: `${cookies["dd_words_done"] ? cookies["dd_words_done"] : ""} <br> <b>I have done writing.</b>`
            });

            if (response.data?.success === true) {
                toast({
                    title: "Letter Email",
                    description: response.data.message,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Letter Email",
                    description: `${response.data.message}.`,
                    bg: 'blue',
                    color: 'white',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: "Letter Email",
                description: `${e.message}.`,
                bg: 'blue',
                color: 'white',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const handleBack = () => {
        navigate("/");
    }

    return (
        <VStack w={"auto"} p={8}>
            {
                addFlag ? (
                    <>
                        <VStack spacing={8}>
                            <Card w={isLargerThan830 ? "auto" : "-webkit-fill-available"} minH={"400px"} minW={isLargerThan830 ? "400px" : "350px"}>
                                <VStack align="end" mr={8}>
                                    <Button w={100} mt={4} onClick={handleBack} size={'sm'} _hover={{ bg: '#3182ce', color: 'white' }}>
                                        Back
                                    </Button>
                                </VStack>
                                <CardHeader>
                                    <Stepper
                                        index={activeStep}
                                        flexDirection={isLargerThan830 ? "row" : "column !important"}
                                        alignItems={isLargerThan830 ? "center" : "start !important"}
                                        paddingLeft={isLargerThan830 ? "0" : "15px !important"}
                                    >
                                        {steps.map((step, index) => (
                                            <Step key={index}>
                                                <StepIndicator>
                                                    <StepStatus
                                                        complete={<StepIcon />}
                                                        incomplete={<StepNumber />}
                                                        active={<StepNumber />}
                                                    />
                                                </StepIndicator>
                                                <Box flexShrink="0">
                                                    <StepTitle>{step.title}</StepTitle>
                                                    <StepDescription>
                                                        {step.description}
                                                    </StepDescription>
                                                </Box>
                                                <StepSeparator />
                                            </Step>
                                        ))}
                                    </Stepper>
                                </CardHeader>
                                <CardBody>
                                    {activeStep === 1 && <DialogueEmotion handleEmotionSelect={handleNext} />}
                                    {activeStep === 2 && <DialogueCategory />}
                                    {activeStep === 3 && <DialogueLetter ref={dialogueLetterRef} />}
                                </CardBody>
                                <CardFooter>
                                    {activeStep !== 1 && (
                                        <HStack w={"full"}>
                                            <Button
                                                onClick={handlePrev}
                                                disabled={activeStep === 1}
                                                _hover={{
                                                    bg: "#3182ce", color: 'white'
                                                }}
                                            >
                                                Previous
                                            </Button>
                                            <Spacer />
                                            <Button colorScheme="blue" onClick={handleSaveDraft} display={activeStep !== 1}>
                                                Save Draft
                                            </Button>
                                            <Button
                                                onClick={handleNext}
                                                disabled={activeStep === 3}
                                                _hover={{
                                                    bg: "#3182ce", color: 'white'
                                                }}
                                            >
                                                {activeStep === 1 ?
                                                    'Next' : activeStep === 2 ? 'Next' : 'Finish'
                                                }
                                            </Button>
                                        </HStack>
                                    )}
                                </CardFooter>
                            </Card>
                        </VStack>
                        <AlertDialog isOpen={isOpen} onClose={onClose}>
                            <AlertDialogOverlay>
                                <AlertDialogContent mx={8}>
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        Save Dialogue
                                    </AlertDialogHeader>

                                    <AlertDialogBody>Are you sure?</AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button onClick={onClose}>Cancel</Button>
                                        <Button
                                            colorScheme="teal"
                                            onClick={handleSaveDialogue}
                                            ml={3}
                                        >
                                            Save
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                    </>
                ) : (
                    <Card w={isLargerThan830 ? "auto" : "-webkit-fill-available"} maxW={'665px'} minH={"400px"} minW={isLargerThan830 ? "400px" : "350px"} p={8}>
                        <CardBody w={'full'}>
                            <Heading fontSize='xl' mt={4}>Question</Heading>
                            <Text mt={2}>{location.state.question}</Text>
                            <Heading fontSize='xl' mt={4}>Emotion</Heading>
                            <Text mt={2}>{emotion === 'Yes' ? 'Pleasant' : 'Unpleasant'}</Text>
                            <Heading fontSize='xl' mt={4}>Feeling Data</Heading>
                            <Text mt={2}>
                                {feelingData.map((feeling) => feeling.text).join(', ')}
                            </Text>
                            <Heading fontSize='xl' mt={4}>Letter</Heading>
                            <Text mt={2}>{content}</Text>
                        </CardBody>
                        <CardFooter w={'full'}>
                            <HStack justifyContent={"flex-end"} w={'full'}>
                                <Button onClick={handleBack} colorScheme="blue">
                                    Back
                                </Button>
                            </HStack>
                        </CardFooter>
                    </Card>
                )
            }
        </VStack>
    );
}

export default WriteDialogue;
