import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormControl,
    VStack,
    useToast,
    Input,
    useColorModeValue,
    FormHelperText,
    Box,
    Container,
    Text,
    HStack,
    Link as ChakraLink
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { FaShare } from "react-icons/fa";
import { API_URLS } from "../Constants";
import axios from "axios";
import { useState } from "react";

function ForgotPassword() {
    const [isSending, setIsSending] = useState(false);
    const toast = useToast();
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const themeColor = useColorModeValue("blue.400", "blue.300");
    const emailRegex = new RegExp(
        /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
        "gm"
    );

    const handleSend = async () => {
        if (!emailValid) {
            return;
        }

        try {
            setIsSending(true);
            const response = await axios.post(API_URLS.PASSWORD_FORGOT, {
                email
            });

            if (response.data.success) {
                toast({
                    title: "Forgot Password",
                    description: ``,
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Forgot Password",
                    description: `${response.data.message}.`,
                    bg: 'blue',
                color: 'white',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSending(false);
        }
    };

    const handleChangeEmail = (e) => {
        if (e.target.value && emailRegex.test(e.target.value)) {
            setEmailValid(true);
            setEmail(e.target.value);
        } else {
            setEmailValid(false);
        }
    };

    return (
        <Box
            className="App-login"
            alignItems={"center"}
            mt={8}
        >
            <Container w={"full"}>
                <Card>
                    <CardHeader>Forgot Password</CardHeader>
                    <CardBody>
                        <VStack spacing={4}>
                            <Text>Enter your email address below to begin the reset process.</Text>
                            <FormControl>
                                <Input
                                    type="Email"
                                    placeholder="Enter email"
                                    defaultValue={email}
                                    onChange={handleChangeEmail}
                                    {...(emailValid
                                        ? { borderColor: "green.500" }
                                        : { borderColor: "red.500" })}
                                />
                                {!emailValid ? (
                                    <FormHelperText color="red.500">
                                        Input valid email address
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )}
                            </FormControl>
                        </VStack>
                    </CardBody>
                    <CardFooter>
                        <VStack w={'full'}>
                            <Button
                                w={"full"}
                                leftIcon={<FaShare />}
                                onClick={handleSend}
                                isLoading={isSending}
                            >
                                SEND INITIAL PASSWORD TO YOUR EMAIL
                            </Button>
                            <HStack>
                                <Text>Back to</Text>
                                <ChakraLink
                                    as={ReactRouterLink}
                                    to="/login"
                                    _hover={{ color: themeColor }}
                                >
                                    <Text>Login</Text>
                                </ChakraLink>
                            </HStack>
                        </VStack>

                    </CardFooter>
                </Card>

            </Container>
        </Box>

    );
}

export default ForgotPassword;
