import {
    Card,
    CardBody,
    CardHeader,
    useToast,
    AspectRatio,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URLS } from "../../Constants";
import axios from "axios";
function Not(props) {
    const [videoLink, setVideoLink] = useState("");
    const toast = useToast();
    useEffect(() => {
        const loadVideo = async () => {
            try {
                const response = await axios.get(API_URLS.MATERIAL_VIDEOS);
                if (response.data.success) {
                    const newLink = `${process.env.REACT_APP_UPLOAD_URL}uploads/video/${response.data.data[0]["notLink"]}`
                    setVideoLink(newLink);
                } else {
                    toast({
                        title: "How NOT to dialogue",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadVideo();


    }, [toast]);
    return (
        <Card minH={"600px"} w={"full"}>
            <CardHeader>{props.title}</CardHeader>
            <CardBody>
                {videoLink && (
                    <AspectRatio ratio={16/9} w={'full'}>
                        <iframe
                            title="How NOT to dialogue"
                            src={videoLink}
                            allowFullScreen
                        />
                    </AspectRatio>
                )}

            </CardBody>
        </Card>
    );
}

export default Not;
