import { useContext } from "react";
import { FaSadTear, FaSmile } from "react-icons/fa";
import { DialogueContext } from "../../providers/DialogueProvider";
import { Button, Container, HStack, Spacer, useMediaQuery, VStack, Text } from "@chakra-ui/react";

const DialogueEmotion = (props) => {
    const [isLargerThan520] = useMediaQuery("(min-width: 520px)");
    const { emotion, setEmotion, addFlag } = useContext(DialogueContext);

    const onHandleEmotionSelect = (emotionVal) => {
        setEmotion(emotionVal);

        props.handleEmotionSelect();
    }

    return (
        <Container w={'full'}>
            <HStack w={'full'} sx={{ display: 'flex', justifyContent: 'center' }} flexDirection={isLargerThan520 ? 'row' : 'column'} gap={isLargerThan520 ? 8 : 2}>
                <Button size={'lg'} p={'50px'} colorScheme="green" onClick={() => { onHandleEmotionSelect('Yes') }}>
                    <VStack>
                        <FaSmile size={'30px'} />
                        <Text>PLEASANT</Text>
                    </VStack>
                </Button>
                <Spacer />
                <Button size={'lg'} p={'50px'} colorScheme="pink" onClick={() => { onHandleEmotionSelect('No') }}>
                    <VStack>
                        <FaSadTear size={'30px'} />
                        <Text>UNPLEASANT</Text>
                    </VStack>
                </Button>
            </HStack>
        </Container>
    )
}

export default DialogueEmotion;