import {
    Box,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepTitle,
    StepIcon,
    StepNumber,
    StepSeparator,
    VStack,
    useSteps,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    HStack,
    Button,
    Spacer,
    useToast,
    useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { API_URLS } from "../Constants";
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { DialogueContext } from "../providers/DialogueProvider";
import { AppContext } from "../providers/AppProvider";
import moment from "moment";
import ShareDialogueContent from "../components/history/ShareDialogueContent";
function ShareDialogue() {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    const { selDialogId } = useContext(DialogueContext);
    const { cookieAlive } = useContext(AuthContext);
    const { contactInfo } = useContext(AppContext);
    const [cookies] = useCookies();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const [timerStatus, setTimerStatus] = useState(0); // 0: Stopped, 1: Running, 2: Paused, 3: Up
    const [selectedRole, setSelectedRole] = useState("husband");
    const [appointment, setAppointment] = useState(null);
    const [curDate, setCurDate] = useState(moment().format("YYYY-MM-DD"));
    const [curType, setCurtype] = useState("");
    const steps = [
        {
            title: "Emotion",
        },
        {
            title: "Feelings",
        },
        {
            title: "Make",
        },
        {
            title: "Share"
        }
    ];
    const dialogueLetterRef = useRef(null);

    const stopDialogueLetterTimer = () => {
        if (dialogueLetterRef.current) {
            dialogueLetterRef.current.stopTimer();
        }
    };

    const { activeStep, setActiveStep } = useSteps({
        index: 4,
        count: steps.length,
    });

    const getAppointment = async (type) => {
        try {
            const response = await axios.post(API_URLS.DIALOGUE_GET_PRESHARE_APPT, {
                appointmentId: selDialogId,
            });
            if (response.data.data) {
                setAppointment(response.data.data);
            } else {
                toast({
                    title: "History",
                    description: `${response.data.message}.`,
                    duration: 3000,
                    isClosable: true,
                    bg: 'blue',
                    color: 'white'
                });
                setAppointment({});
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        if (!selDialogId) {
            navigate("/");
        }

        if (cookies['dd_user_email'] == cookies['dd_h_email']) {
            handleHusband();
        } else if (cookies['dd_user_email'] == cookies['dd_w_email']) {
            handleWife();
        }

    }, [cookieAlive, selDialogId, navigate]);

    const handleBack = () => {
        navigate("/");
    }

    const handleStart = useCallback(async () => {
        if (timerStatus == 0) {
            setTimerStatus(1);
        } else {
            if (timerStatus == 1)
                setTimerStatus(2);
            else if (timerStatus == 2)
                setTimerStatus(1);
        }
        try {
            const response = await axios.post(API_URLS.DIALOGUE_GET_PRESHARE_APPT, {
                appointmentId: location.state.dataId,
            });

            if (response.data.success) {
                if (response.data.data['preshareMain'] && response.data.data['preshareSpouse']) {
                    if (timerStatus == 0)
                        setTimerStatus(1);
                } else {
                    toast({
                        title: "Share dialogue",
                        description: `Your spouse isn't ready to share yet.`,
                        bg: 'blue',
                        color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            }
        } catch {

        }
    });

    const handleFinish = useCallback(async () => {
        try {
            const response = await axios.post(API_URLS.DIALOGUE_SHARE_APPT, {
                appointmentId: selDialogId,
                userEmail: cookies['dd_user_email']
            });
            if (response.data.success) {
                setTimerStatus(3);
                stopDialogueLetterTimer();
                navigate('/history');
            }
        } catch (e) {

        }
    });

    const handleTimerStatus = useCallback((status) => {
        setTimerStatus(status);
    });

    const handleWife = useCallback(() => {
        setSelectedRole('wife');
        console.log(cookies['dd_w_email'], cookies['dd_h_email'] , cookies['dd_user_email'])
        if (cookies['dd_w_email'] === cookies['dd_user_email']) {
            getAppointment('master');
            setCurtype('master');
        } else if (cookies['dd_h_email'] === cookies['dd_user_email']) {
            getAppointment('spouse');
            setCurtype('spouse');
        }
    });

    const handleHusband = useCallback(() => {
        setSelectedRole('husband');
        console.log(cookies['dd_w_email'] , cookies['dd_user_email'])
        if (cookies['dd_h_email'] === cookies['dd_user_email']) {
            getAppointment('master');
            setCurtype('master');
        } else if (cookies['dd_w_email'] === cookies['dd_user_email']) {
            getAppointment('spouse');
            setCurtype('spouse');
        }
    });

    return (
        <VStack p={8}>
            <Card w={isLargerThan830 ? "auto" : "full"} minH={"400px"} minW={isLargerThan830 ? "665px" : "350px"}>
                <VStack align="end" mr={8}>
                    <Button w={100} mt={4} onClick={handleBack} size={'sm'} _hover={{ bg: '#3182ce', color: 'white' }}>
                        Back
                    </Button>
                </VStack>
                <CardHeader>
                    <VStack w={'full'} gap={4}>
                        <HStack justifyContent={'center'} w={'full'}>
                            <Button colorScheme={selectedRole === 'husband' ? 'blue' : 'gray'} px={8} onClick={e => { handleHusband() }}>
                                Husband
                            </Button>
                            <Button colorScheme={selectedRole === 'wife' ? 'blue' : 'gray'} px={8} onClick={e => { handleWife() }}>
                                Wife
                            </Button>
                        </HStack>
                        <Stepper
                            index={activeStep}
                            flexDirection={isLargerThan830 ? "row" : "column !important"}
                            alignItems={isLargerThan830 ? "center" : "start !important"}
                            paddingLeft={isLargerThan830 ? "0" : "15px !important"}
                            w={'full'}
                        >
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>
                                    <Box flexShrink="0">
                                        <StepTitle>{step.title}</StepTitle>
                                    </Box>
                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>
                    </VStack>
                </CardHeader>
                <CardBody>
                    {appointment && <ShareDialogueContent ref={dialogueLetterRef} timerStatus={timerStatus} setTimerStatus={handleTimerStatus} type={curType} data={appointment}/>}
                </CardBody>
                <CardFooter>
                    {activeStep !== 1 && (
                        <HStack w={"full"}>
                            <Button
                                onClick={() => { handleStart(); }}
                                colorScheme={timerStatus == 3 ? "gray" : "blue"}
                                disabled={timerStatus == 3}
                                _hover={{
                                    bg: timerStatus == 3 ? "inherit" : "#3182ce", color: timerStatus == 3 ? "inherit" : 'white'
                                }}

                            >
                                {timerStatus == 0 ? 'Start' : timerStatus == 1 ? 'Pause' : timerStatus == 2 ? 'Resume' : 'Start'}
                            </Button>
                            <Spacer />

                            <Button
                                onClick={() => { handleFinish(); }}
                                colorScheme="blue"
                                _hover={{
                                    bg: "#3182ce", color: 'white'
                                }}
                            >
                                End
                            </Button>
                        </HStack>
                    )}
                </CardFooter>
            </Card>
        </VStack>

    );
}

export default ShareDialogue;
